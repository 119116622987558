<template>
	<div>
		<!--@멤버십-->
		<div class="com_box_membership" v-if="Object.keys(this.$store.getters.getUsingMembership).length > 0">
			<h1>
				{{ this.$store.getters.getUsingMembership.baseChannelInfoModel.baseChannelModel.name }}
				<!--<span class="txt">{{
					$constants.serviceTypeDisplay[
						this.$store.getters.getUsingMembership.baseChannelInfoModel
							.baseChannelModel.serviceCategoryModel.serviceType
					]
				}}</span>-->
			</h1>
			<span class="txt sub">Membership</span>
			<span class="com_tag">
				<span class="txt">사용자 {{ this.$store.getters.getUsingMembership.userCnt }}인</span>
			</span>
		</div>

		<!--@결제금액-->
		<div class="wrap_form">
			<div class="com_list">
				<table class="com_tb_data large tb_membership_payment">
					<thead>
						<tr>
							<th class="svc"><span class="txt">서비스명</span></th>
							<th class="add_extension">
								<span class="txt">주문 종류</span>
							</th>
							<th class="item flex"><span class="txt">상품명</span></th>
							<th class="num"><span class="txt">수량</span></th>
							<th class="period"><span class="txt">사용기간</span></th>
							<th class="sum"><span class="txt">결제금액(원)</span></th>
						</tr>
					</thead>
					<tbody>
						<template v-for="service in tableData">
							<template v-for="(item, index) in service">
								<tr
									v-if="item.type == 'main'"
									:key="item.serviceName + '_' + item.itemName + +'_' + item.addExtension + '_main'"
								>
									<td class="svc" v-if="index == 0" :rowspan="service.length">
										<span v-if="item.serviceName.indexOf('경리회계') > -1" class="ic_svc ic_accounting"></span>
										<span v-if="item.serviceName == '경비처리(무증빙)'" class="ic_svc ic_bizplay"></span>
										<span v-if="item.serviceName == 'TMSe 장비일체형 S'" class="ic_svc ic_tmse_s"></span>
										<span v-if="item.serviceName == 'TMSe 장비일체형 E'" class="ic_svc ic_tmse_e"></span>
										<span v-if="item.serviceName == 'TMSe 장비일체형 T'" class="ic_svc ic_tmse_share"></span>
										<span class="txt">{{ item.serviceName }}</span>

										<span class="com_tag" v-if="item.freeTrialPolicyModel && item.freeTrialPolicyModel.usePolicy">
											<span class="txt">{{ item.freeTrialPolicyModel.usagePeriod }}개월 무료체험</span>
										</span>
									</td>
									<td class="add_extension">
										<span class="txt">{{ item.addExtension }}</span>
									</td>
									<td class="item flex">
										<span class="txt">{{ item.itemName }}</span>
									</td>
									<td class="num">
										<span class="txt"> {{ item.count }}</span>
									</td>
									<td class="period">
										<span class="txt">{{ item.usePeriod }}</span>
									</td>
									<td class="sum">
										<span class="txt">{{ item.finalPrice | currency }}원</span><br />
										<span v-if="item.finalPrice < item.originPrice" class="txt line_through"
											>{{ item.originPrice | currency }}원</span
										>
									</td>
								</tr>
								<tr
									v-if="item.type == 'sub'"
									class="sub"
									:key="index + '_' + item.serviceName + '_' + item.itemName + '_' + item.addExtension + '_sub'"
								>
									<!--@sub data-->
									<td class="add_extension"></td>
									<td class="item flex">
										<span class="ic_set ic_depth"></span>
										<span class="txt">{{ item.itemName }}</span>
									</td>
									<td class="num">{{ item.count }}</td>
									<td class="period"></td>
									<td class="sum">
										<span class="txt">{{ item.finalPrice | currency }}</span>
									</td>
								</tr>
							</template>
						</template>
					</tbody>

					<tbody class="summary">
						<tr>
							<td class="svc" colspan="5">
								<span class="txt">상품 금액</span>
							</td>
							<td class="sum">
								<span class="txt">{{ this.$store.getters.getAddExtensionPrices.totOriginPrice | currency }}원</span>
							</td>
						</tr>
						<tr class="emphasise">
							<td class="svc" colspan="5">
								<span class="txt">할인 금액</span>
							</td>
							<td class="sum">
								<span class="txt">{{ this.$store.getters.getAddExtensionPrices.totDiscountPrice | currency }}원</span>
							</td>
						</tr>
						<!--할인 금액-->
						<!--<template
							v-for="row in this.$store.getters.getAddExtensionDiscount"
						>
							<tr v-if="parseInt(row.amount) < 0" class="sub" :key="row.name">
								<td class="svc" colspan="4">
									<span class="ic_set ic_depth"></span>
									<span class="txt">{{ row.name }}</span>
								</td>
								<td class="sum">
									<span class="txt"> {{ row.amount | currency }}원 </span>
								</td>
							</tr>
						</template>-->
						<tr>
							<td class="svc" colspan="5">
								<span class="txt" v-if="isChange">멤버십전환금액</span>
								<span class="txt" v-else>상품 금액 - 할인 금액</span>
							</td>
							<td class="sum">
								<span class="txt"
									>{{
										($store.getters.getAddExtensionPrices.totOriginPrice +
											$store.getters.getAddExtensionPrices.totDiscountPrice)
											| currency
									}}원</span
								>
							</td>
						</tr>

						<template v-if="isChange">
							<tr>
								<td class="svc" colspan="5">
									<span class="txt">잔여금액</span>
								</td>
								<td class="sum">
									<span class="txt">{{ (-1 * this.$store.getters.getRefundableAmount) | currency }}원</span>
								</td>
							</tr>
							<tr>
								<td class="svc" colspan="5">
									<span class="txt">멤버십전환금액 - 잔여금액</span>
								</td>
								<td class="sum">
									<span class="txt"
										>{{
											$store.getters.getAddExtensionPrices.totPayPrice > 0
												? $store.getters.getAddExtensionPrices.totPayPrice
												: 0 | currency
										}}원</span
									>
								</td>
							</tr>
						</template>
					</tbody>
					<tr>
						<td class="svc" colspan="5">
							<span class="txt">부가세(10%)</span>
						</td>
						<td class="sum">
							<span class="txt">{{ this.$store.getters.getAddExtensionPrices.surtaxPrice | currency }}원</span>
						</td>
					</tr>
					<tfoot class="total">
						<tr>
							<td class="svc">
								<span class="txt">결제 금액(VAT포함)</span>
							</td>
							<td class="sum" colspan="5">
								<span class="txt">{{ this.$store.getters.getAddExtensionPrices.finalPayPrice | currency }}원</span>
							</td>
						</tr>
						<tr v-if="this.$store.getters.getUsingMembership.autoExtension">
							<td colspan="5">
								<span class="txt"
									>{{ nextPayDate }}기존 자동결제 금액에 추가한 상품의 월 사용료가 합산되어 결제될 예정입니다.
								</span>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		isChange() {
			return this.$store.getters.getFixedOrderType === 'CHANGE' ? true : false;
		},
	},
	data() {
		var nextPayDate = this.getNextPayDate();
		return {
			nextPayDate,
			tableData: [],
		};
	},
	async created() {
		this.tableData = await this.setTable();
	},
	methods: {
		async cloneObj(obj) {
			return JSON.parse(JSON.stringify(obj));
		},
		async setTable() {
			var table = [];
			let services = [];
			await this.$store.getters.getBaseChannel.reduce((previous, baseChannel) => {
				return previous.then(async () => {
					services.push(baseChannel);
				});
			}, Promise.resolve());

			await this.$store.getters.getAddonChannel.reduce((previous, addonChannel) => {
				return previous.then(async () => {
					services.push(addonChannel);
				});
			}, Promise.resolve());
			let addEndDate, extensionEndDate;
			await services.reduce((previous, service) => {
				addEndDate =
					this.$store.getters.getNewMembershipChargeType === 'FREE'
						? '만료일 없음'
						: service.baseInfo.usingDate.addEndDate;

				extensionEndDate =
					this.$store.getters.getNewMembershipChargeType === 'FREE'
						? '만료일 없음'
						: service.baseInfo.usingDate.extensionEndDate;

				return previous.then(async () => {
					let serviceTable = [];
					let typeServices = [];
					await service.extension.reduce((previous, extension) => {
						return previous.then(async () => {
							let obj = await this.cloneObj(extension);
							obj['type'] = 'EXTENSION';
							typeServices.push(obj);
						});
					}, Promise.resolve());

					await service.add.reduce((previous, add) => {
						return previous.then(async () => {
							let obj = await this.cloneObj(add);
							obj['type'] = 'ADD';
							typeServices.push(obj);
						});
					}, Promise.resolve());

					await typeServices.reduce((previous, typeService) => {
						return previous.then(async () => {
							if (typeService.defaultFeeTotDiscountPrice !== undefined && typeService.defaultFeePolicyModel.usePolicy) {
								let row = [];
								row.serviceName = service.baseInfo.name;
								if (typeService.type == 'EXTENSION') {
									row.addExtension = '연장';
									row.itemName = '기본료';
									row.count = typeService.usePeriod + '개월 ';
								} else if (typeService.type == 'ADD') {
									row.addExtension = this.isChange ? '유료 전환' : '추가';
									row.itemName = '기본료';
									row.count = '';
									if (typeService.usePeriod.remainMonth > 0) {
										row.count = typeService.usePeriod.remainMonth + '개월';
									}
									row.count += typeService.usePeriod.remainDay + '일';
								}
								row.freeTrialPolicyModel = service.baseInfo.freeTrialPolicyModel;
								if (typeService.type == 'EXTENSION') {
									row.usePeriod =
										service.baseInfo.usingDate.extensionStartDate + '~' + service.baseInfo.usingDate.extensionEndDate;
								} else if (typeService.type == 'ADD') {
									row.usePeriod = service.baseInfo.usingDate.addStartDate + '~' + addEndDate;
								}
								row.finalPrice = typeService.defaultFeeTotDiscountPrice;
								row.originPrice = typeService.defaultFeeTotNormalPrice;
								row.type = 'main';
								serviceTable.push(row);
							}

							if (
								typeService.addUserTotDiscountPrice !== undefined &&
								typeService.addUserCnt > 0 &&
								typeService.addUserIntervalPolicyModel.usePolicy
							) {
								let row = [];
								row.serviceName = service.baseInfo.name;

								if (typeService.type == 'EXTENSION') {
									row.addExtension = '연장';
									row.itemName = '사용자 추가';
								} else if (typeService.type == 'ADD') {
									row.addExtension = this.isChange ? '유료 전환' : '추가';
									row.itemName = '사용자 추가';
								}

								row.freeTrialPolicyModel = service.baseInfo.freeTrialPolicyModel;
								row.count = typeService.addUserCnt + '명';
								if (typeService.type == 'EXTENSION') {
									row.usePeriod = service.baseInfo.usingDate.extensionStartDate + '~' + extensionEndDate;
								} else if (typeService.type == 'ADD') {
									// 신요금제 전환 대상이면 [추가연장 == 추가하면서 연장] 하므로 applyDate 는 연장기간과 동일
									const applyDate = this.$store.getters.isExtensionOrAddExtensionOrderWithConversionProduct
										? service.baseInfo.usingDate.extensionStartDate
										: service.baseInfo.usingDate.addStartDate;
									row.usePeriod = applyDate + '~' + addEndDate;
								}
								row.finalPrice = typeService.addUserTotDiscountPrice;
								row.originPrice = typeService.addUserTotNormalPrice;
								row.type = 'main';
								serviceTable.push(row);

								if (typeService.addUserDiscountMatrix) {
									typeService.addUserDiscountMatrix.forEach((obj) => {
										let subRow = [];
										subRow.serviceName = service.baseInfo.name;
										subRow.itemName = obj.interval + '명 (구간)';
										subRow.count = obj.discountCnt + '명';
										subRow.usePeriod = '';
										subRow.finalPrice = obj.totPrice + '원';
										subRow.originPrice = obj.totPrice + '원';
										subRow.type = 'sub';
										serviceTable.push(subRow);
									});
								}
							}
							if (
								typeService.membershipFeeDiscountPrice !== undefined &&
								typeService.membershipFeePolicyModel.usePolicy
							) {
								let row = [];
								row.serviceName = service.baseInfo.name;
								row.itemName = '설치비';
								row.freeTrialPolicyModel = service.baseInfo.freeTrialPolicyModel;
								row.count = '1회';
								if (typeService.type == 'EXTENSION') {
									row.usePeriod = service.baseInfo.usingDate.extensionStartDate + '~' + extensionEndDate;
								} else if (typeService.type == 'ADD') {
									row.usePeriod = service.baseInfo.usingDate.addStartDate + '~' + addEndDate;
								}
								row.finalPrice = typeService.membershipFeeDiscountPrice;
								row.originPrice = typeService.membershipFeeNormalPrice;
								row.type = 'main';
								serviceTable.push(row);
							}
						});
					}, Promise.resolve());

					await service.option.reduce((previous, option) => {
						return previous.then(async () => {
							await option.add.reduce((previous, addOption) => {
								return previous.then(async () => {
									let row = [];
									// 신요금제 전환 대상이면 [추가연장 == 추가하면서 연장] 하므로 applyDate 는 연장기간과 동일
									const applyDate = this.$store.getters.isExtensionOrAddExtensionOrderWithConversionProduct
										? service.baseInfo.usingDate.extensionStartDate
										: service.baseInfo.usingDate.addStartDate;
									row.serviceName = service.baseInfo.name;
									row.addExtension = this.isChange ? '유료 전환' : '추가';
									row.itemName = addOption.displaySectionModel.displayName;
									row.freeTrialPolicyModel = service.baseInfo.freeTrialPolicyModel;
									row.count = addOption.orderCount + '개';
									row.usePeriod = applyDate + '~' + addEndDate;
									row.finalPrice = addOption.finalPrice;
									row.originPrice = addOption.finalPrice;
									row.type = 'main';
									serviceTable.push(row);
								});
							}, Promise.resolve());
							await option.extension.reduce((previous, extensionOption) => {
								return previous.then(async () => {
									let row = [];
									row.serviceName = service.baseInfo.name;
									row.freeTrialPolicyModel = service.baseInfo.freeTrialPolicyModel;
									row.addExtension = '연장';
									row.itemName = extensionOption.displaySectionModel.displayName;
									row.count = extensionOption.orderCount + '개';

									row.usePeriod = service.baseInfo.usingDate.extensionStartDate + '~' + extensionEndDate;
									row.finalPrice = extensionOption.finalPrice;
									row.originPrice = extensionOption.finalPrice;
									row.type = 'main';
									serviceTable.push(row);
								});
							}, Promise.resolve());
						});
					}, Promise.resolve());
					table.push(serviceTable);
				});
			}, Promise.resolve());

			return table;
		},
		isAutoExtensionFreeTrial(service) {
			if (
				service.freeTrialPolicyModel.usePolicy &&
				service.usingCount > 0 &&
				this.$store.getters.getUsingMembership.autoExtension
			) {
				return true;
			}

			return false;
		},
		getNextPayDate() {
			const usingMembership = this.$store.getters.getUsingMembership;

			let date = new Date(usingMembership.membershipBaseInfoModel.expirationDate);
			let year = '';
			let month = '';
			let day = '';

			date.setDate(date.getDate() - 1);
			year = new String(date.getFullYear());
			month = new String(date.getMonth() + 1);
			day = new String(date.getDate());
			if (month.length == 1) {
				month = '0' + month;
			}
			if (day.length == 1) {
				day = '0' + day;
			}
			return year + '년 ' + month + '월 ' + day + '일 ';
		},
	},
};
</script>

<style></style>
